import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

import { dimensions } from '../styles/variables'

let currentYear = new Date().getFullYear()

const logoSVG = require('../../images/cusociety-logo.svg')
const logoPNG = require('../../images/cusociety-logo.png')

interface FooterProps {
  className?: string
  title: string
}

const HomepageLink = styled(Link)`
  width: 3.75rem;
  margin: 1rem;
`

const FooterLogo: React.SFC<FooterProps> = ({ title }) => (
  <picture title={title}>
    <source type="image/svg+xml" srcSet={logoSVG} />
    <img src={logoPNG} alt={title} />
  </picture>
)

const FooterText = styled.p`
  color: #eee;
  padding: 1rem;
  align-self: center;
`

const FooterSection = styled.div`
  color: #eee;
  text-align: right;
  padding: 1rem;

  a {
    color: #eee;
  }

  a:hover {
    color: #fff;
  }

  h3 {
    font-size: ${dimensions.headingSizes.h5}rem;
    color: #eee;
  }

  ul {
    list-style-type: none;
  }
`

const Footer: React.SFC<FooterProps> = ({ className, title }) => (
  <footer className={className}>
    <HomepageLink to="/">
      <FooterLogo title={title} />
    </HomepageLink>
    <FooterText>&copy; 2018 &ndash; { currentYear } CU Projects Pte Ltd and CU Society.</FooterText>
    <FooterSection>
      <h3>Contact</h3>
      <ul>
        <li>
          <a href="https://www.facebook.com/CUSocietySG/" aria-label="CU Society's Facebook Page">
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </a>
        </li>
      </ul>
    </FooterSection>
  </footer>
)

export default styled(Footer)`
  display: flex;
  justify-content: space-between;
  background-color: #111;
`
