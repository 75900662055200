import * as React from 'react'
import Helmet from 'react-helmet'

const jsTrackerURL = '//dq946n1u2xxy.cloudfront.net/t.js'
const snowplowName = 'sp'

interface InitProps {
  collector?: string
  appId?: string
  platform?: string
  discoverRootDomain?: boolean
}

const defaultCollectorId = 'd2g9jemm2mmx68.cloudfront.net' // CU Society's CloudFront Collector
const defaultAppId = 'cusg-website'
const defaultPlatform = 'web'

const Snowplow: React.SFC<InitProps> = ({
  collector = defaultCollectorId,
  appId = defaultAppId,
  platform = defaultPlatform,
  discoverRootDomain = true
}) => (
  <Helmet>
    <script type="text/javascript">
      {`
      ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
        p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
        };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
        n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","${jsTrackerURL}","${snowplowName}"));

        ${snowplowName}('newTracker', 'cf', '${collector}', { // Initialise a tracker
          appId: "${appId}",
          platform: "${platform}",
          discoverRootDomain: ${discoverRootDomain}
        });

        ${snowplowName}('trackPageView')
      `}
    </script>
  </Helmet>
)

export default Snowplow
