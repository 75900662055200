import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

type StaticQueryProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const staticDir = '' // Gatsby copies /static to root so this might not be needed
const cacheQueryString = '?v=180817'

const Favicons: React.SFC = () => (
  <StaticQuery
    query={graphql`
      query FaviconTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={`${staticDir}/apple-touch-icon.png${cacheQueryString}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${staticDir}/favicon-32x32.png${cacheQueryString}`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${staticDir}/favicon-16x16.png${cacheQueryString}`} />
        <link rel="manifest" href={`${staticDir}/site.webmanifest${cacheQueryString}`} />
        <link rel="mask-icon" href={`${staticDir}/safari-pinned-tab.svg${cacheQueryString}`} color="#5bbad5" />
        <link rel="shortcut icon" href={`${staticDir}/favicon.ico${cacheQueryString}`} />
        <meta name="apple-mobile-web-app-title" content={`${data.site.siteMetadata.title}`} />
        <meta name="application-name" content={`${data.site.siteMetadata.title}`} />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta name="msapplication-config" content={`${staticDir}/browserconfig.xml${cacheQueryString}`} />
        <meta name="theme-color" content="#f7f7f7" />
      </Helmet>
    )}
  />
)

export default Favicons
