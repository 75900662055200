import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Favicons from './head/Favicons'
import { dimensions } from '../styles/variables'

const logoSVG = require('../../images/cusociety-logo.svg')
const logoPNG = require('../../images/cusociety-logo.png')

interface HeaderProps {
  className?: string
  background?: string
  position?: string
  title: string
}

const HomepageLink = styled(Link)`
  position: absolute;
  width: 3.75rem;
  flex-shrink: 0; // keep title from being taken over by navbar
  line-height: 0;

  @media (max-width: 767px) {
    position: relative;
  }
`

const HeaderLogo: React.SFC<HeaderProps> = ({ title }) => (
  <picture title={title}>
    <source type="image/svg+xml" srcSet={logoSVG} />
    <img src={logoPNG} alt={title} />
  </picture>
)

const Navbar = styled.nav`
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
`

const NavWrap = styled.div`
  max-width: 49rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const NavLink = styled(Link)`
  height: 2rem;
  padding: 0 0.5rem;
  text-decoration: none;
  font-size: ${dimensions.fontSize.large}px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  color: #f7f7f7;
  text-transform: uppercase;

  &:hover {
    color: #fff;
    border-bottom: 2px solid yellow;
  }
`

const Header: React.SFC<HeaderProps> = ({ className, title }) => (
  <header className={className}>
    <Favicons />
    <Navbar>
      <HomepageLink to="/">
        <HeaderLogo title={title} />
      </HomepageLink>
      <NavWrap />
    </Navbar>
  </header>
)

export default styled(Header)`
  width: 100%;
  max-width: 100vw;
  padding: 1rem;
  z-index: 10;

  background: ${props => (props.background !== undefined ? props.background : 'inherit')};
  position: ${props => (props.position !== undefined ? props.position : 'absolute')};
`
