import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/global'

import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Footer from '../components/Footer'

import Snowplow from '../components/trackers/Snowplow'

type StaticQueryProps = {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const pageDescription =
  'CU Society brings together tech communities in Singapore by organising and supporting computer technology events, conferences, and workshops.'

const IndexLayout: React.SFC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={`${data.site.siteMetadata.title} - ${data.site.siteMetadata.description}`}
          meta={[{ name: 'description', content: pageDescription }]}
        >
          <html lang="en" />
        </Helmet>
        <Snowplow />
        <Header title={data.site.siteMetadata.title} />
        <LayoutMain>{children}</LayoutMain>
        <Footer title={data.site.siteMetadata.title} />
      </LayoutRoot>
    )}
  />
)

export default IndexLayout
